<template>
	<div class="styleguide">
		<h1>Styleguide</h1>
		<section>
			<header>
				<h2>Typography</h2>
			</header>

			<h3>Headlines</h3>
			<h1>h1</h1>
			<h2>h2</h2>
			<h3>h3</h3>
			<h4>h4</h4>
			<h5>h5</h5>
			<h6>h6</h6>

			<h3>Paragraphs</h3>
			<p>Lorem ipsum dolor sit, alias incidunt. Aliquam quod officiis sunt aperiam voluptate itaque quasi ipsa ut ipsam assumenda error accusantium repudiandae aliquid, omnis eaque dolorum eum corrupti ducimus sed neque vitae ad odit reprehenderit! Error animi saepe dolorum, veritatis tenetur mollitia distinctio deserunt exercitationem ut harum perferendis similique doloremque necessitatibus delectus ipsam maiores molestiae quod itaque voluptatem rem. Eos, blanditiis soluta dignissimos similique!</p>
			<p>Veritatis, quia. Mollitia cumque reiciendis porro, corrupti illum tenetur fuga, ad ipsa cupiditate, ullam asperiores accusamus. Iusto expedita debitis error necessitatibus dolorum totam aliquid excepturi quae quasi tenetur minima doloremque, illum consequatur magni consectetur asperiores repudiandae temporibus ut saepe dolores. Magnam ratione earum soluta odit veritatis repellendus aperiam alias voluptatibusi!</p>
		</section>

		<section>
			<header>
				<h2>Buttons</h2>
			</header>
			<h3>Button brands</h3>
			<p>
				<button class="button">default</button>
				<button class="button is-primary">primary</button>
				<button class="button is-success">success</button>
				<button class="button is-info">info</button>
				<button class="button is-danger">danger</button>
				<button class="button is-error">error</button>
				<button class="button is-link">link</button>
			</p>
			<p>
				<button class="button">default</button>
				<button class="button is-primary is-light">primary</button>
				<button class="button is-success is-light">success</button>
				<button class="button is-info is-light">info</button>
				<button class="button is-danger is-light">danger</button>
				<button class="button is-error is-light">error</button>
			</p>
			<h3>Button sizes</h3>
			<p>
				<button class="button">default</button>
				<button class="button is-small">small</button>
				<button class="button is-large">large</button>
				<button class="button is-fullwidth">full width</button>
			</p>

		</section>

	</div>
</template>

<script>

export default {
  name: 'StyleGuide',
  components: {
    
  }
}
</script>
